@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: 'Garet Book', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background: white;
  color: #131716;
}

a {
  text-decoration: none;
  color: #F4E9E2;
}

p {
  margin: 0;
}

h1, h2 {
  font-family: 'EB Garamond', serif;
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border-color: red;
  box-shadow: 0 0 0 0.1rem rgba(red, 0.25) !important;
}

.text-justify {
  text-align: justify;
}

.portal-button {
  background: #1C3C57;
  border: 1px solid #D7B689;
  color: #F4E9E2;
  padding: 0.5rem 1.5rem;
  border-radius: 0;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background: #1C3C57;
    border-color: #1C3C57;
  }
}

.tag {
  background: #1C3C57;
  padding: 0.75rem;
  font-weight: bold;
  color: #F4E9E2;
}

.form-select {
  border-radius: 0;
  border-color: #F4E9E2;
  box-shadow: none !important;

  &:focus {
    border-color: #D7B689;
  }
}

.spotlight {
  color: #D7B689;
  font-family: 'EB Garamond', serif;
  font-weight: bold;
}

.form-control {
  box-shadow: none !important;

  &:focus {
    border-color: #1C3C57 !important;
  }
}

.btn-primary {
  --bs-btn-bg: #1C3C57;
  --bs-btn-border-color: #D7B689;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #D7B689;
  --bs-btn-hover-color: black;
  --bs-btn-hover-border-color: #D7B689;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #9da2aa;
  --bs-btn-disabled-border-color: #9da2aa;

  padding: 0.75rem 3rem;
  border-radius: 0;
  font-weight: 600;
  border-width: 2px;
}

.profile-picture {
  object-fit: cover;
}

